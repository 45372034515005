<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row>
        <b-col md="8">
          <b-card>
            <b-row class="mr-1 position-absolute position-right-0">
              <b-col class="text-right">
                <b-badge :variant="statusVariant(form.statusCurrent)">
                  <span>{{ form.statusName }}</span>
                </b-badge>
              </b-col>
            </b-row>
            <b-tabs>
              <b-tab title="Detail">
                <b-row
                  v-if="form.statusCurrent != 2 && form.statusCurrent != 3"
                >
                  <b-col>
                    <b-form-group label="Status" label-for="status">
                      <b-form-select
                        id="type"
                        v-model="form.status"
                        :options="statusOpt"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Kode" label-for="code">
                      <b-form-input
                        id="code"
                        placeholder="Kode"
                        v-model="form.code"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Kategori" label-for="category">
                      <b-form-select
                        id="category"
                        v-model="form.selectedCategory"
                        :options="form.optionsCategory"
                        required
                      />
                      <!-- Selected: <strong>{{ form.selectedCategory }}</strong> -->
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Merk" label-for="brand">
                      <b-form-input
                        id="brand"
                        placeholder="Merk"
                        v-model="form.brand"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Nama" label-for="name">
                      <b-form-input
                        id="name"
                        placeholder="Name"
                        v-model="form.name"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Warna" label-for="color">
                      <b-form-input
                        id="color"
                        placeholder="Warna"
                        v-model="form.color"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Tahun" label-for="year">
                      <b-form-input
                        id="year"
                        placeholder="Tahun"
                        v-model="form.year"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Transmisi" label-for="transmission">
                      <b-form-input
                        id="transmission"
                        placeholder="Transmisi"
                        v-model="form.transmission"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Tipe Mesin" label-for="machineType">
                      <b-form-input
                        id="machineType"
                        placeholder="Tipe Mesin"
                        v-model="form.machineType"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Kapasitas Mesin"
                      label-for="engineCapacity"
                    >
                      <b-form-input
                        id="engineCapacity"
                        placeholder="Kapasitas Mesin"
                        v-model="form.engineCapacity"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Jumlah Penumpang"
                      label-for="Passengers"
                    >
                      <cleave
                        id="passengers"
                        placeholder="Jumlah Penumpang"
                        v-model="form.passengers"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Jumlah Ban" label-for="tires">
                      <b-form-input
                        id="tires"
                        placeholder="Jumlah Ban"
                        v-model="form.tires"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Golongan" label-for="Gologan">
                      <b-form-input
                        id="class"
                        placeholder="Golongan"
                        v-model="form.class"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nomor Polisi" label-for="policeNo">
                      <b-form-input
                        id="policeNo"
                        placeholder="Nomor Polisi"
                        v-model="form.policeNo"
                        required
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal berakhir STNK"
                      label-for="registration-date"
                    >
                      <flat-pickr
                        v-model="form.registrationDate"
                        class="form-control"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nomor STNK" label-for="registrationNo">
                      <b-form-input
                        id="registrationNo"
                        placeholder="Nomor STNK"
                        v-model="form.registrationNo"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Nomor Rangka" label-for="chassisNo">
                      <b-form-input
                        id="chassisNo"
                        placeholder="Nomor Rangka"
                        v-model="form.chassisNo"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nomor Mesin" label-for="machineNo">
                      <b-form-input
                        id="machineNo"
                        placeholder="Nomor Mesin"
                        v-model="form.machineNo"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal KIR"
                      label-for="KIR-date"
                    >
                      <flat-pickr
                        v-model="form.kirDate"
                        class="form-control"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal Pembelian"
                      label-for="purchase-date"
                    >
                      <flat-pickr
                        v-model="form.purchaseDate"
                        class="form-control"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Harga Pembelian" label-for="price">
                      <cleave
                        id="price"
                        placeholder="Harga"
                        v-model="form.price"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal Penjualan"
                      label-for="purchase-date"
                    >
                      <flat-pickr
                        v-model="form.sellingDate"
                        class="form-control"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Harga Jual" label-for="price">
                      <cleave
                        id="price"
                        placeholder="Harga"
                        v-model="form.sellingPrice"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Kilometer" label-for="mileage">
                      <cleave
                        id="mileage"
                        placeholder="Kilometer"
                        v-model="form.mileage"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                        required
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Tanggal Update"
                      label-for="update-date"
                    >
                      <flat-pickr
                        v-model="form.mileageDate"
                        class="form-control"
                        :config="{
                          dateFormat: 'd-m-Y',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-form-group label="Deskripsi" label-for="description">
                  <quill-editor v-model="form.description" />
                </b-form-group>
              </b-tab>
              <b-tab title="Foto">
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      :state="errors"
                      label="Left"
                      label-for="Left"
                      invalid-feedback="Foto utama tidak boleh kosong"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Foto"
                        rules="required"
                      >
                        <b-img
                          id="photoURL"
                          :src="photo"
                          fluid
                          alt="product photo"
                          class="mb-1"
                          v-if="(hasImage === '') | null"
                        />
                        <div class="file-area file-left">
                          <image-uploader
                            :preview="true"
                            :className="[
                              'fileinput',
                              { 'fileinput--loaded': hasImage },
                            ]"
                            :debug="0"
                            :autoRotate="true"
                            accept="image/*"
                            doNotResize="['gif', 'svg']"
                            outputFormat="string"
                            @input="setImage"
                          ></image-uploader>
                        </div>
                        <small class="text-danger">{{
                          errors[0] || errMessageImage
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>

          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mr-1"
                @click="updateProduct()"
                block
              >
                Perbaharui
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4">
          <b-card>
            <b-form-group
              :state="errors"
              label="Foto"
              label-for="Image"
              invalid-feedback="Foto tidak boleh kosong"
            >
              <validation-provider
                #default="{ errors }"
                name="Foto"
                rules="required"
              >
                <b-img
                  id="photoURL"
                  :src="photo"
                  fluid
                  alt="product photo"
                  class="mb-1"
                  v-if="(hasImage === '') | null"
                />
                <image-uploader
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  :debug="0"
                  :autoRotate="true"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  outputFormat="string"
                  @input="setImage"
                ></image-uploader>
                <small class="text-danger">{{
                  errors[0] || errMessageImage
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            v-b-modal.modal-history
          >
            Lihat Sejarah Unit
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="requestUpdateMileage()"
          >
            Minta Pembaruan Kilometer
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            v-b-modal.modal-reminder
          >
            Kirim Pengingat
          </b-button>
          <b-modal
            id="modal-history"
            title="Sejarah Unit"
            ok-title="Close"
            cancel-variant="outline-secondary"
            centered
            hide-footer
          >
            <app-timeline>
              <app-timeline-item
                v-for="(log, index) in logs"
                :key="index"
                :title="log.description"
                subtitle=""
                :time="moment(log.created_at)"
                :variant="log.variant"
              />
            </app-timeline>
          </b-modal>
          <b-modal
            id="modal-reminder"
            title="Kirim Pengingat"
            ok-title="Send"
            cancel-variant="outline-secondary"
            centered
            hide-footer
          >
            <validation-observer ref="loginValidation">
              <b-form ref="form" @submit.prevent>
                <b-form-group label="Type" label-for="type">
                  <b-form-select
                    id="type"
                    v-model="reminder.type"
                    :options="reminderOpt"
                    required
                  />
                  <!-- Selected: <strong>{{ form.selectedCategory }}</strong> -->
                </b-form-group>
                <b-row>
                  <b-col>
                    <b-button
                      class="mt-1 mb-2"
                      type="submit"
                      variant="primary"
                      block
                      @click="sendReminder()"
                    >
                      Kirim
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BBadge,
  BContainer,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import moment from "moment";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    flatPickr,
    BBadge,
    BContainer,
    BTabs,
    BCardText,
    BTab,
  },
  directives: {
    Ripple,
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-dark",
        1: "light-success",
        2: "light-warning",
        3: "light-dark",
        4: "light-primary",
        5: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },
  data() {
    return {
      form: {
        code: "",
        name: "",
        selectedCategory: null,
        optionsCategory: [],
        mileage: null,
        purchaseDate: null,
        registrationDate: null,
        kirDate: null,
        description: "",
        price: null,
        transmission: null,
        passengers: null,
        engineCapacity: null,
        brand: null,
        color: null,
        year: null,
        registrationNo: null,
        policeNo: null,
        chassisNo: null,
        machineNo: null,
        machineType: null,
        class: null,
        tires: null,
        status: null,
        statusOld: null,
        statusName: null,
        sellingDate: null,
        sellingPrice: null,
        mileageDate: null,
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      logs: [],
      permissions: [],
      hasImage: "",
      image: "",
      photo: "",
      photos: {},
      errors: "",
      errMessage: "",
      errMessageImage: "",
      reminderOpt: [
        {
          value: 1,
          text: "Service",
        },
        {
          value: 2,
          text: "STNK",
        },
        {
          value: 3,
          text: "KIR",
        },
      ],
      selectedStatus: null,
      statusOpt: [
        {
          value: 1,
          text: "Tersedia"
        },
        {
          value: 4,
          text: "Dalam Perbaikan"
        },
        {
          value: 5,
          text: "Tidak Aktif / Terjual"
        },
      ],
      reminder: {
        description: "",
        type: null,
      },
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getAllPermissions();
    this.getCategories();
    this.getDetails();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    moment(date) {
      return moment(date).format("DD-MM-YYYY, hh:mm");
    },
    getCategories() {
      this.$http.get("unit/categories/all").then((response) => {
        // console.log(response.data.data)
        this.form.optionsCategory.push({
          value: null,
          text: "Pilih salah satu kategori",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.form.optionsCategory.push($data);
          } else {
            var $dataParent = {
              value: datas[i].id,
              text: datas[i].name,
              disabled: true,
            };
            this.form.optionsCategory.push($dataParent);

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.form.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.form.optionsCategory)
      });
    },
    getDetails() {
      const id = this.$route.params.id;
      const { baseUrl } = $themeConfig.app;
      const imageUri = baseUrl + "image/unit/";

      this.$http.get("unit/" + id).then((response) => {
        // console.log(response.data.data)
        this.form.code = response.data.data.code;
        this.form.name = response.data.data.name;
        this.form.selectedCategory = response.data.data.category_id;
        this.form.mileage = response.data.data.mileage;
        this.form.purchaseDate = response.data.data.purchase_date;
        this.form.registrationDate = response.data.data.registration_date;
        this.form.kirDate = response.data.data.kir_date;
        this.form.description = response.data.data.description;
        this.form.price = response.data.data.price;
        this.form.transmission = response.data.data.transmission;
        this.form.passengers = response.data.data.passengers;
        this.form.engineCapacity = response.data.data.engine_capacity;
        this.form.brand = response.data.data.brand;
        this.form.color = response.data.data.color;
        this.form.year = response.data.data.year;
        this.form.registrationNo = response.data.data.registration_no;
        this.form.policeNo = response.data.data.police_no;
        this.form.chassisNo = response.data.data.chassis_no;
        this.form.machineNo = response.data.data.machine_no;
        this.form.machineType = response.data.data.machine_type;
        this.form.class = response.data.data.class;
        this.form.tires = response.data.data.tires;
        this.form.sellingPrice = response.data.data.selling_price;
        this.form.sellingDate = response.data.data.selling_date;
        this.form.mileageDate = response.data.data.mileage_date;

        this.form.status = response.data.data.status;
        this.form.statusCurrent = response.data.data.status;
        this.form.statusName = response.data.data.status_name;
        this.photo = imageUri + response.data.data.image;
        this.photos = this.getImages(imageUri, response.data.data.images);
        this.image = this.getBase64Image(document.getElementById("photoURL"));
        this.getLogs();
        console.log(this.form.statusCurrent);
      });
    },
    getImages(uri, datas) {
      var i;
      for (i = 0; i < datas.length; i++) {
        // var $data = {
        //       image: uri + datas[i].image,
        //       type: datas[i].type,
        //     };
        // this.photos.push($data);

        switch (datas[i].type) {
          case 1: {
            this.photos.left = uri + datas[i].image;
            break;
          }
          case 2: {
            this.photos.right = uri + datas[i].image;
            break;
          }
          case 3: {
            this.photos.front = uri + datas[i].image;
            break;
          }
          case 4: {
            this.photos.rear = uri + datas[i].image;
            break;
          }
          case 5: {
            this.photos.int = uri + datas[i].image;
            break;
          }
        }
      }
      console.log(this.photos);
    },
    getLogs() {
      const id = this.$route.params.id;
      this.$http.get("unit/" + id + "/logs").then((response) => {
        // console.log(response.data.data)
        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          if (i === datas.length - 1) {
            var $data = {
              description: datas[i].description,
              created_at: datas[i].created_at,
              variant: "primary",
            };
            this.logs.push($data);
          } else {
            var $data = {
              description: datas[i].description,
              created_at: datas[i].created_at,
              variant: "dark",
            };
            this.logs.push($data);
          }
        }
      });
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      this.image = canvas.toDataURL("image/png");
      this.image += this.image.replace(/^data:image\/(png|jpg);base64,/, "");
    },
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
    updateProduct() {
      const id = this.$route.params.id;
      var newMileage = this.form.mileage;

      if (newMileage != null) {
        newMileage = newMileage.replace(/\,/g, "");
      }

      var newPrice = this.form.price;

      if (newPrice != null) {
        newPrice = newPrice.replace(/\,/g, "");
      }

      var newSellingPrice = this.form.sellingPrice;

      if (newSellingPrice != null) {
        newSellingPrice = newSellingPrice.replace(/\,/g, "");
      }
      console.log("status: " + this.form.status)

      this.$http
        .post("unit/" + id + "/update", {
          code: this.form.code,
          name: this.form.name,
          category_id: this.form.selectedCategory,
          mileage: newMileage,
          purchase_date: this.form.purchaseDate,
          registration_date: this.form.registrationDate,
          kir_date: this.form.kirDate,
          image: this.image,
          description: this.form.description,
          transmission: this.form.transmission,
          price: newPrice,
          passengers: this.form.passengers,
          engine_capacity: this.form.engineCapacity,
          brand: this.form.brand,
          color: this.form.color,
          year: this.form.year,
          registration_no: this.form.registrationNo,
          police_no: this.form.policeNo,
          chassis_no: this.form.chassisNo,
          machine_no: this.form.machineNo,
          machine_type: this.form.machineType,
          class: this.form.class,
          tires: this.form.tires,
          selling_date: this.form.sellingDate,
          selling_price: this.form.sellingPrice,
          mileage_date: this.form.mileageDate,
          status: this.form.status,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Unit berhasil diperbaharui",
              variant: "success",
            },
          });
          location.href = "/units";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deleteProduct() {
      const id = this.$route.params.id;
      this.$http
        .post("unit/" + id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Unit berhasil dihapus",
              variant: "danger",
            },
          });
          location.href = "/units";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
    requestUpdateMileage() {
      const id = this.$route.params.id;
      this.$http
        .get("unit/" + id + "/request/mileage")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan Pembaruan Kilometer berhasil terkirim",
              variant: "success",
            },
          });
          // location.href = "/units";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    sendReminder() {
      const id = this.$route.params.id;
      this.$http
        .post("unit/" + id + "/reminder", {
          type: this.reminder.type,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Pengingat berhasil dikirim",
              variant: "success",
            },
          });
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
#category option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#category option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
img.img-preview {
  width: 100%;
  margin-bottom: 15px;
}
</style>